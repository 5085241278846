.table-width {
  width:1000px;
  overflow: auto;
}

.table-by-customer {
  width:1200px;
  overflow: auto;
}

.col-total {
  background-color: yellow;
  border-top: 1px solid black;
  text-align: center;
}
.col-total-start {
  border-left: 1px solid black;
}

.col-total-end {
  border-right: 1px solid black;
}

.active-border{
  border:1px solid black;
}

.col-no {
  width: 2.5%;
}

.col-customer {
  width: 15%;
}

.col-label {
  width: 15%;
}

.col-skid-size {
  width: 18%;
}

.col-size {
  width: 5%;
}

.col-skid-asset {
  width: 9%;
}

.col-skid-in {
  width: 9%;
}

.col-skid-out {
  width: 9.5%;
}
