.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #f4801a;
}

.collapseActive {
  padding: 10px 5px;
  margin-left: 20px;
}

.collapseInactive {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
}

.headerMod {
  padding: 0 10px !important;
  background: #fff !important;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.logo {
  /* display: flex; */
  /* flex-direction: row; */
  /* width: 500px; */
}

.sidebar {
  box-shadow: 0 0 28px 0 rgba(255, 100, 24, 0.1);
}

.contentMod {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
}

.footer {
  text-align: center;
  background: #fff;
  margin-top: 20;
}

.content {
  flex: 1;
  margin: 16px 16px 0px 16px;
}
