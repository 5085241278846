.wrap_main {
  letter-spacing: 0.01cm;
}

.section_main {
  border: 1px solid black; width: 100%; 
}

.wrap_section_one {
  display: flex;
  border-bottom: 1px solid black;
  padding: 10px 0;
}

.wrap_logo {
  width: 200px;
  text-align: center;
}

.logo {
  width: 110px; height: 110px;
}

.wrap_title {
  width: 600px;
}

.address {
  width: 600px;
  font-weight: 700;
}

.text_title {
  font-size: 18px;
  font-weight: 700;
}

.wrap_section_two {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}

.text_right {
 width: 500px; 
}

.text_right_two {
  width: 300px; 
 }

 .delivery_note{
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  padding-left:5px;
 }

.wrap_section_three {
  display: flex;
  padding: 10px 0;
}
.wrap_orderby {
  width: 200px;
  text-align: right;
  padding-right: 10px;
  font-weight: 700;
}

.wrap_section_four {
  display: flex;
  border-top: 1px solid black;
  padding: 10px 0;
}

.table{
  /* border: 1px solid black; */
  width: 100%;
}

.no_table {
  width: 5%;
  border:1px solid black;
}

.skid_id {
  width: 35%;
  border:1px solid black;
}
 
.skid_product {
  width: 55%;
  border:1px solid black;
}

.border_data > td, .border_data > th{
  border:1px solid black;
  text-align: center;
}

.border_bottom {
  width: 16.7%;
  text-align: center;
  vertical-align: top;
  height: 80px;
}