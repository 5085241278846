.table-width {
  width: 100% !important;
  overflow: auto;
}

.col-total {
  background-color: yellow;
  border-top: 1px solid black;
  text-align: center;
}
.col-total-start {
  border-left: 1px solid black;
}

.col-total-end {
  border-right: 1px solid black;
}

.active-border {
  border: 1px solid black;
}

.col-no {
  width: 3.5%;
}

.col-customer {
  width: 25%;
}

.col-label {
  width: 25%;
}

.col-skid-size {
  width: 25%;
}

.col-size {
  width: 6%;
}

.col-skid-asset {
  width: 6%;
}

.col-skid-in {
  width: 6%;
}

.col-skid-out {
  width: 6%;
}

.col-skid-status {
  width: 4%;
}
