.wrap_main {
  letter-spacing: 0.01cm;
  padding: 10px;
  border:1px solid black;
}

.section_main {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
}

.title {
  border: 1px solid black;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 20px;
}

.wrap_section_one {
  display: flex;
  border-bottom: 1px solid black;
  padding: 10px 0;
}

.wrap_logo {
  display: flex;
}


.warp_work_cop {
  display: flex;
  border:1px solid black;
  padding: 2px;
}

.warp_date_cop {
  display: flex;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 2px;
}

.date_value {
  width: 150px;
}

.space {
  padding-right: 10px;
}

.logo {
  width: 50px; height: 50px;
  margin-right: 10px;

}

.wrap_title {
  width: 600px;
}

.address {
  width: 600px;
  font-weight: 700;
}

.text_title {
  font-size: 18px;
  font-weight: 700;
}

.wrap_section_two {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}

.text_right {
 width: 500px; 
}

.text_right_two {
  width: 300px; 
 }

 .delivery_note{
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  padding-left:5px;
 }

.wrap_section_three {
  display: flex;
  padding: 10px 0;
}
.wrap_orderby {
  width: 200px;
  text-align: right;
  padding-right: 10px;
  font-weight: 700;
}

.wrap_section_four {
  display: flex;
  border-top: 1px solid black;
  padding: 10px 0;
}

.table{
  /* border: 1px solid black; */
  width: 100%;
}

.no_table {
  width: 5%;
  border:1px solid black;
}

.skid_id {
  width: 35%;
  border:1px solid black;
}
 
.skid_product {
  width: 55%;
  border:1px solid black;
}

.border_data > td, .border_data > th{
  border:1px solid black;
  text-align: center;
}

.border_bottom {
  width: 16.7%;
  text-align: center;
  vertical-align: top;
  height: 80px;
}

.ttd {
  border: 1px solid black;
  width: 100px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.ttd_other {
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 100px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.wrap_tdd {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wrap_tdd_section {
  display: flex;
}

.note {
  font-size: 12px;
  width: 55%;
  margin-right: 20px;
}

.wrap_note {
  margin-top: 10px;
}

.note_two {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text_note_two {
  font-size: 10px;
  width: 45%;
}

.letter_id {
  padding: 5px 10px;
}